export function updateEmptyStringsToNull(obj, keys) {
  keys.forEach((key) => {
    if (!Object.is(obj[key], null)) {
      obj[key].trim().length === 0 && (obj[key] = null);
    }
  });
}

export function filterAndExtractImagePath(imageValue) {
  let featuredImage = imageValue;
  // 데이터 보낼 때, 이미지가 없거나 빅크 epmty 이미지이면 null로 보내기로 논의.
  const isImage =
    typeof featuredImage === "string" &&
    featuredImage.length > 0 &&
    !featuredImage.includes("statics/commons/images/empty");

  if (isImage) {
    const url = featuredImage && new URL(featuredImage);
    featuredImage = url.pathname;
  } else {
    featuredImage = null;
  }
  return featuredImage;
}
